import theme, { withTransientProps } from "@/styles/theme";
import styled from "@emotion/styled";
import { TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Grid,
  Tab,
  Tabs,
  TabsProps,
  Typography,
} from "@mui/material";

import Image from "next/image";

export const HomeHeaderStyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: "center";

  ${theme.breakpoints.down("md")} {
    margin-top: ${(p) => p.theme.spacing(10)};
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  ${theme.breakpoints.up("xs")} {
    height: 100%;
    gap: ${(p) => p.theme.spacing(2)};
  }
  ${theme.breakpoints.up("md")} {
    height: 60%;
    gap: ${(p) => p.theme.spacing(3)};
  }
  ${theme.breakpoints.only("md")} {
    padding-right: ${(p) => p.theme.spacing(3)};
  }
`;

export const HomeHeaderContainer = styled(Box)`
  && {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    padding-top: ${(p) => p.theme.spacing(8)};
    padding-bottom: ${(p) => p.theme.spacing(10)};

    ${theme.breakpoints.down("md")} {
      padding: 0;
    }
  }
`;

export const HomeResponsiveContainer = styled(Container)`
  && {
    padding: ${(p) => p.theme.spacing(4, 3, 0, 3)};

    ${theme.breakpoints.up("lg")} {
      padding: ${(p) => p.theme.spacing(10, 10, 0, 10)};
    }

    ${theme.breakpoints.only("md")} {
      padding: ${(p) => p.theme.spacing(10, 10, 0, 10)};
    }
  }
`;

export const HeaderButton = styled(Button)`
  && {
    align-self: baseline;
    background: ${(p) => p.theme.palette.black[900]};
    color: #ffffff;
    margin-top: ${(p) => p.theme.spacing(4.5)};
    padding: ${(p) => p.theme.spacing(1.25, 2)};
    font-size: 16px;
    font-weight: 400;
  }
`;

type HomeContainerProps = {
  $hideMobile?: boolean;
};

export const HomeContentContainer = styled(
  "div",
  withTransientProps,
)<HomeContainerProps>`
  && {
    width: 100%;
    padding-top: ${(p) => p.theme.spacing(10)};

    ${theme.breakpoints.down("md")} {
      padding-top: ${(p) => p.theme.spacing(13)};
    }

    ${theme.breakpoints.down("md")} {
      ${(p) => p.$hideMobile && "display: none"}
    }

    ${theme.breakpoints.down("sm")} {
      padding-top: ${(p) => p.theme.spacing(2)};
    }
  }
`;

export const HeaderInfoTitle = styled(Typography)`
  && {
    ${theme.breakpoints.down("sm")} {
      font-size: 48px;
      line-height: 52px;
    }
  }
`;

export const HeaderInfoSubTitle = styled(Typography)`
  margin-top: ${(p) => p.theme.spacing(2)};
  padding-right: ${(p) => p.theme.spacing(15)};
  && {
    ${theme.breakpoints.down("sm")} {
      padding-right: 0;
    }
  }
`;

export const TabContainer = styled(Box)`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${(p) => p.theme.spacing(5)};
  }
`;

export const TabListStyled = styled(TabList)`
  && {
    padding: ${(p) => p.theme.spacing(0.5)};
    border: 1px solid ${(p) => p.theme.palette.black[200]};
    border-radius: 10px;
  }
`;

const tabIndicatorProps = {
  style: {
    backgroundColor: theme.palette.grey[400],
    height: "100%",
    width: "200px",
    borderRadius: theme.spacing(1),
    color: theme.palette.grey[900],
    display: "block",
  },
};

const BaseStyledTabs: React.FC<TabsProps> = ({ ...props }) => (
  <Tabs {...props} TabIndicatorProps={tabIndicatorProps} />
);

export const StyledTabs = styled(BaseStyledTabs)(({ theme }) => ({
  padding: theme.spacing(0.5),
  border: `1px solid ${theme.palette.black[200]}`,
  borderRadius: theme.spacing(1.25),
}));

export const StyledTab = styled(Tab)`
  && {
    z-index: 1;
    background: none;
    width: 200px;
    padding: 0;
    text-transform: none;
  }
`;

export const TabPanelStyled = styled(TabPanel)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`;

export const AdvertisingTitleContainer = styled(Box)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;

    ${theme.breakpoints.down("sm")} {
      padding-top: ${(p) => p.theme.spacing(6)};
    }
  }
`;

export const AdvertisingTitleOne = styled(Typography)`
  && {
    ${theme.breakpoints.down("sm")} {
      font-size: 24px;
    }
  }
`;

export const AdvertisingTitleTwo = styled(Typography)`
  && {
    ${theme.breakpoints.down("sm")} {
      font-size: 24px;
    }
  }
`;

export const AdvertisingStyledHeart = styled(Image)`
  && {
    margin: ${(p) => p.theme.spacing(0, 1)};

    ${theme.breakpoints.down("sm")} {
      max-width: 10%;
      height: auto;
    }
  }
`;

export const AdvertisingStyledSubTitle = styled(Typography)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: ${(p) => p.theme.spacing(1.5)};
    text-align: center;
  }
`;

export const AdvertiserLogosContainer = styled(Box)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: ${(p) => p.theme.spacing(1)};
    padding: ${(p) => p.theme.spacing(4, 0, 15)};

    ${theme.breakpoints.down("md")} {
      flex-wrap: wrap;
    }
  }
`;

interface ImageProps {
  $display: string;
}

export const AdvertiserImageContainer = styled(
  Box,
  withTransientProps,
)<ImageProps>`
  && {
    flex: 1 1 calc(12.5% - 8px);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;

    ${theme.breakpoints.down("md")} {
      flex: 1 1 calc(25% - 8px);
    }

    ${theme.breakpoints.down("sm")} {
      flex: 1 1 calc(28% - 8px);
      display: ${(p) => (p.$display === "desktop" ? "none" : "block")};
    }
  }
`;

export const StyledImageAdvertising = styled(Image)`
  && {
    max-width: 100%;
    height: auto;
  }
`;

type BackgroundProps = {
  $color?: string;
  $background?: string;
};

export const HomeBackgroundContainer = styled("div")<BackgroundProps>`
  && {
    background-color: ${(p) => p.$color};
    background: ${(p) => p.$background};
    width: 100%;
  }
`;

export const WorkflowContainer = styled(Box)`
  && {
    padding-bottom: ${(p) => p.theme.spacing(20)};

    ${theme.breakpoints.down("sm")} {
      padding-bottom: ${(p) => p.theme.spacing(10)};
    }
  }
`;

export const WorkflowSubTitle = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(p) => p.theme.spacing(2.5, 50, 0, 50)};
    color: ${(p) => p.theme.palette.black[200]};
    text-align: center;

    ${theme.breakpoints.down("xl")} {
      padding: ${(p) => p.theme.spacing(2.5, 30)};
    }
    ${theme.breakpoints.down("md")} {
      padding: ${(p) => p.theme.spacing(2.5, 0, 0)};
    }
  }
`;

export const WorkflowTitle = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;

    ${theme.breakpoints.down("sm")} {
      font-size: 40px;
      line-height: 48px;
    }

    ${theme.breakpoints.down("md")} {
      font-size: 48px;

      line-height: 48px;
    }
  }
`;

export const WorkflowFeatureContainer = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(p) => p.theme.spacing(15, 0, 20)};

    ${theme.breakpoints.down("md")} {
      margin: ${(p) => p.theme.spacing(8, 0, 9)};
    }
  }
`;

export const WorkflowLeft = styled(Grid)`
  && {
    ${theme.breakpoints.down("md")} {
      order: 2;
    }
  }
`;

export const WorkflowRight = styled(Grid)`
  && {
    ${theme.breakpoints.down("md")} {
      order: 1;
      margin-bottom: ${(p) => p.theme.spacing(4)};
    }
  }
`;

type FeatureProps = {
  $paddingRight?: boolean;
};

export const WorkflowFeatureInfo = styled(
  "div",
  withTransientProps,
)<FeatureProps>`
  && {
    padding-right: ${(p) => (p.$paddingRight ? p.theme.spacing(10) : 0)};
    padding-left: ${(p) => (p.$paddingRight ? 0 : p.theme.spacing(6))};

    ${theme.breakpoints.down("md")} {
      padding: 0;
    }
  }
`;

export const StyledImage = styled(Image)`
  && {
    max-width: 100%;
    height: auto;
  }
`;

export const StyledTabImage = styled("img")`
  && {
    max-width: 100%;
    height: auto;
  }
`;

export const WorkflowFeatureGridContainer = styled(Box)`
  && {
    display: flex;
    gap: 24px;

    ${theme.breakpoints.down("md")} {
      flex-wrap: wrap;
    }
  }
`;

export const WorkflowFeatureGridLeft = styled(Box)`
  && {
    flex: 0 1 75%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    ${theme.breakpoints.down("md")} {
      flex: 0 1 100%;
    }
  }
`;

export const WorkflowFeatureGridRight = styled(Box)`
  && {
    flex: 0 1 25%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${theme.breakpoints.down("md")} {
      flex: 0 1 100%;
    }
  }
`;

export const WorkflowFeatureGridBlockOne = styled(Box)`
  && {
    display: flex;
    border-radius: 10px;
    background: linear-gradient(
      to bottom,
      rgba(62, 23, 142, 0.7) -518.72%,
      rgba(146, 94, 255, 0.6) 11.44%,
      #925eff 100%
    );

    flex: 1 1 100%;
    padding: ${(p) => p.theme.spacing(8, 8)};
  }
`;

export const WorkflowFeatureGridBlockTwo = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: linear-gradient(
      to bottom,
      #f482b6 39.34%,
      rgba(142, 76, 106, 0.9) 252.53%
    );
    flex: 1 1 calc(50% - 12px);
    padding: ${(p) => p.theme.spacing(9, 8)};
    align-items: center;

    ${theme.breakpoints.down("sm")} {
      flex: 0 1 100%;
    }
  }
`;

export const WorkflowFeatureGridBlockThree = styled(Box)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    background-color: #121316;
    flex: 1 1 calc(50% - 12px);
    padding: ${(p) => p.theme.spacing(9, 8)};

    ${theme.breakpoints.down("sm")} {
      flex: 0 1 100%;
    }
  }
`;

export const WorkflowFeatureGridBlockFour = styled(Box)`
  && {
    border-radius: 10px;
    background: linear-gradient(
      to bottom,
      rgba(67, 148, 196, 0.768969) -48.73%,
      rgba(74, 165, 217, 0.852718) -3.98%,
      #57c1ff 83.23%
    );

    padding: ${(p) => p.theme.spacing(9, 8)};
  }
`;

export const WorkflowFeatureGridBlockFive = styled(Box)`
  && {
    border-radius: 10px;
    background: linear-gradient(180deg, #d57704 -29.41%, #f9a947 125.77%);
    padding: ${(p) => p.theme.spacing(9, 8)};
    flex-grow: 1;
  }
`;

export const WorkflowGridTitle = styled(Typography)`
  && {
    align-self: baseline;
    color: #fff;
    padding-bottom: ${(p) => p.theme.spacing(2)};
  }
`;

export const WorkflowGridSubTitle = styled(Typography)`
  && {
    align-self: baseline;
    color: #fff;
  }
`;

export const WorkflowGridTitleContainer = styled(Box)`
  && {
    display: block;
  }
`;

export const HomePricing = styled(Box)`
  && {
    padding: ${(p) => p.theme.spacing(8, 0)};
    background: #f2f2f2;
  }
`;

export const SignupContainer = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 800px;

    ${theme.breakpoints.down("md")} {
      height: 700px;
    }

    ${theme.breakpoints.down("sm")} {
      height: 500px;
    }
  }
`;

export const SignupTitle = styled(Typography)`
  && {
    ${theme.breakpoints.down("sm")} {
      font-size: 40px;
      line-height: 42px;
    }
  }
`;

export const SignupContent = styled(Box)`
  && {
    width: 100%;
    text-align: center;
    padding: ${(p) => p.theme.spacing(0, 35)};

    ${theme.breakpoints.down("md")} {
      padding: 0;
    }
  }
`;

export const SignupButton = styled(Button)`
  && {
    background: #ffffff;
    color: #000000;
    margin-top: ${(p) => p.theme.spacing(4)};
    padding: ${(p) => p.theme.spacing(1.25, 4)};
    font-size: 16px;
    font-weight: 500;
    border: 0;
  }
`;
